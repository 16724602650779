import * as React from "react"
import { graphql } from "gatsby"
import PdfLink from '../components/PdfLink/PdfLink';
import Timestamps from '../reports-and-accounts-timestamps.json';


import Layout from "../components/layout";

const Modern = ({data}) => {
  const pdfs = data.allFile.edges

  return (
    <Layout>
      <h1 className="mb-8">Modern Day Slavery Documents</h1>
      {pdfs
        .sort((a,b) => Date.parse(String(Timestamps[a.node.name]).replace(/(nd|st|rd|th)/g, '')) < Date.parse(String(Timestamps[b.node.name]).replace(/(nd|st|rd|th)/g, ''))  ? 1 : -1)
        .map(pdf => {
          return (
            <div className="py-2" key={pdf.node.id}>
                <PdfLink name={pdf.node.name} publicURL={pdf.node.publicURL} />
            </div>
          )
      })}
      <hr className="mt-2"/>
    </Layout>
  )
}

export default Modern

export const pageQuery = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "modernDay-data" } }) {
        edges {
          node {
            extension
            name
            id
            publicURL
            relativePath
            modifiedTime
          }
        }
      }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`